import styled from "styled-components";

export const MobileHeaderWrap = styled.div`
  display: none;
  width: 100%;
  height: 6rem;
  background-color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
  position: relative;
  z-index: 9;
  .container {
    position: relative;
    .menu-btn {
      width: 2.8rem;
      height: auto;
      position: absolute;
      left: 2rem;
      top: 50%;
      transform: translateY(-50%);    
      span {
        display: block;
        width: 100%;
        height: 0.2rem;
        background-color: rgba(0, 0, 0, 0.54);  
        &:nth-child(2) {
          margin: 0.6rem 0;
        }
      }
    }
    #logo {
      display: block;
      /* background: url('/image/logo.png') no-repeat center / cover;
      width: 9.1rem;
      height: 3.2rem; */
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      a {
        display: block;
        font-size: 2rem;
        color: #c4af99;
      }
    }
  }
  @media screen and (max-width: 1240px) {
    display: block;
  }
`

export const MobileSideMenuWrap = styled.div`
  display: none;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  .bg {
    display: none;
    width: 100%;
    height: 100vh;
    background-color: rgba(33, 33, 33, 0.46);
  }
  .menu-wrap {
    width: 100%;
    height: auto;
    position: absolute;
    left: -100%;
    top: 0;
    background-color: #fff;
    transition: all ease 0.2s;
    .title-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 4.8rem;
      background-color: #c4af99;
      padding: 0 1.5rem;
      h3 {
        font-size: 1.6rem;
        font-weight: 400;
        color: #fff;
      }
      .close-btn {
        span {
          color: #fff;
          font-size: 2.4rem;
          font-weight: 400;
        }
      }
    }
    .menu {
      width: 100%;
      height: auto;
      .dep1 {
        > li {
          border-bottom: 1px solid #dfdfdf;
          > a, button {
            display: block;
            width: 100%;
            height: 4.8rem;
            padding: 0 1.5rem;
            font-size: 1.6rem;
            line-height: 4.8rem;
            font-weight: 700;
            text-align: left;
            position: relative;
            color: #333;
            > span {
              position: absolute;
              right: 1.5rem;
              top: 50%;
              transform: translateY(-50%);
              font-size: 2.4rem;
              color: rgba(0, 0, 0, 0.54);
              transition: all ease 0.3s;
            }
          }
          .dep2 {
            height: 0;
            overflow: hidden;
            transition: height ease 0.3s;
            li {
              a {
                display: block;
                width: 100%;
                height: 4.8rem;
                font-size: 1.6rem;
                line-height: 4.8rem;
                font-weight: 400;
                text-align: left;
                padding: 0 1.5rem;
              }
            }
          }
          &.dep2-on {
            > a, button {
              color: #c4af99;
              > span {
                transform: translateY(-50%) rotate(-180deg);
                color: #c4af99;
              }
            }
            .dep2 {
              height: 9.7rem;
              border-top: 0.1rem solid #dfdfdf;
            }
          }
        }
      }
    }
    .sevice-wrap {
      width: 100%;
      height: auto;
      padding: 1.5rem;
      border-bottom: 0.1rem solid #dfdfdf;
      .sub-title {
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 0.1rem solid #dfdfdf;
      }
      .service {
        a {
          display: block;
          font-size: 1.6rem;
          font-weight: 400;
          b {
            font-size: 2.8rem;
            font-weight: 500;
            color: #c4af99;
          }
        }
        span {
          display: block;
          font-size: 1.2rem;
          font-weight: 400;
          margin-top: 1rem;
        }
      }
    }
    .user-info {
      width: 100%;
      height: 4.8rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 1.5rem;
      a, button {
        display: flex;
        align-items: center;
        margin-left: 2rem;
        img {
          width: 1.6rem;
        }
        span {
          display: block;
          font-size: 1.4rem;
          color: #999;
          margin-left: 0.5rem;
        }
      }
    }
  }
  &.active {
    .bg {
      display: block;
    }
    .menu-wrap {
      left: 0;
    }  
  }
  @media screen and (max-width: 1240px) {
    display: block;
  }
`

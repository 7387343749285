import styled from "styled-components";

export const TextareaBoxWrap = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: auto;
  border: 0.1rem solid #dfdfdf;
  border-radius: 0.4rem;
  position: relative;
  transition: all ease 0.3s;
  padding: 2rem 1.5rem;
  &.focus {
    border: 0.1rem solid #c4af99;
  }
  span {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1.4rem;
    position: absolute;
    left: 1rem;
    top: -0.7rem;
    background-color: #fff;
    transition: all ease 0.3s;
    padding: 0 0.5rem;
    &.focus {
      color: #c4af99;
    }
  }
  textarea {
    width: 100%;
    height: 24rem;
    font-size: 1.6rem;
    caret-color: #c4af99;
    resize: none;
    &:focus {
      outline: none;
    }
  }
  @media screen and (max-width: 500px) {
    textarea {
      height: 20rem;
      font-size: 1.4rem;
    }
  }
`

import styled from "styled-components";

export const Section = styled.section`
  &.p70 {
    padding: 7rem 0;
  }
  &.pb70 {
    padding-bottom: 7rem;
  }
  .cardListWrap {
    margin-top: 4rem;
  }
  &.board-wrap {
    background-color: #f5f5f5;
    .container {
      display: flex;
      gap: 2.5rem;
      > div {
        flex: 1;
      }
      .title-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.2rem solid #cdcdcd;
        padding-bottom: 1rem;
        h3 {
          font-size: 2.5rem;
          font-weight: 700;
          color: #c4af99;
        }
        button {
          display: block;
          width: 3rem;
          height: 3rem;
          background: url('/image/plus-icon.png') no-repeat center / cover;
        }
      }
      .notice {
        ul {
          padding-top: 1rem;
          li {
            &:not(:first-child) {
              margin-top: 1rem;
            }
            a {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .title {
                width: calc(100% - 11rem);
                font-size: 1.6rem;
                font-weight: 700;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
              }
              .date {
                width: 11rem;
                font-size: 1.6rem;
                text-align: right;
              }
            }
            &.empty {
              font-size: 1.6rem;
              font-weight: 400;
              text-align: center;
              padding: 3rem 0;
            }
          }
        }
      }
      .sub-title {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        h4 {
          font-size: 1.6rem;
          font-weight: 700;
          margin-left: 1rem;
        }
      }
      dl {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        dt {
          font-size: 1.8rem;
          font-weight: 700;
          margin-right: 1.5rem;
        }
        dd {
          font-size: 1.6rem;
          strong {
            font-size: 2.8rem;
            font-weight: 700;
            color: #c4af99;
          }
        }
      }
      p {
        font-size: 1.6rem;
        margin-top: 1rem;
      }
    }
  }
  @media screen and (max-width: 768px) {
    &.board-wrap {
      .container {
        display: block;
        > div {
          &:first-child {
            margin-bottom: 4rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    &.board-wrap {
      .container {
        > div {
          &:first-child {
            margin-bottom: 3rem;
          }
        }
        .title-wrap {
          border-bottom: 0.1rem solid #cdcdcd;
          h3 {
            font-size: 2.2rem;
          }
          button {
            width: 2.8rem;
            height: 2.8rem;
          }
        }
        .notice {
          ul {
            li {
              a {
                .title {
                  font-size: 1.4rem;
                }
                .date {
                  font-size: 1.4rem;
                }
              }
              &.empty {
                font-size: 1.4rem;
              }
            }
          }
        }
        .sub-title {
          img {
            width: 2.4rem;
          }
          h4 {
            font-size: 1.4rem;
          }
        }
        dl {
          dt {
            font-size: 1.6rem;
            margin-right: 1rem;
          }
          dd {
            font-size: 1.4rem;
            strong {
              font-size: 2.6rem;
            }
          }
        }
        p {
          font-size: 1.4rem;
        }
      }
    }
    &.p70 {
      padding: 4rem 0;
    }
    &.pb70 {
      padding-bottom: 4rem;
    }
    .cardListWrap {
      margin-top: 3rem;
    }
  }
`

export const Title = styled.div`
  margin-bottom: 3.5rem;
  h2 {
    font-size: 3.7rem;
    line-height: 1.3;
    font-weight: 400;
    text-align: center;  
    word-break: keep-all;
    b {
      color: #c4af99;
    }
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 3rem;
    h2 {
      font-size: 3.2rem;
    }
  }
  @media screen and (max-width: 500px) {
    h2 {
      font-size: 2.8rem;
    }
  }
  @media screen and (max-width: 450px) {
    h2 {
      font-size: 2.6rem;
    }
  }
  @media screen and (max-width: 410px) {
    h2 {
      font-size: 2.4rem;
    }
  }
  @media screen and (max-width: 380px) {
    h2 {
      font-size: 2.2rem;
    }
  }
`
